import HeaderImage from './HeaderImage';
import HeaderSlider from './HeaderSlider';
import HeaderVideo from './HeaderVideo';

const COMPONENTS = {
  image: HeaderImage,
  video: HeaderVideo,
  slider: HeaderSlider,
};

const getHeaderComponent = (content) => {
  const componentKey = Object.keys(content).filter(function (key) {
    if (COMPONENTS[key]) return COMPONENTS[key];
  });

  if (!componentKey) {
    console.error(`Component ${componentKey} doesn't exist.`);
    return false;
  }

  return COMPONENTS[componentKey];
};

export default function DynamicHeader({
  content,
  logo,
  showSwordLogo = true,
  redirectToApp = true,
  noSpacerBottom,
  userName,
}) {
  if (!content) {
    console.error("Please provide a 'content' property to the DynamicHeader");
    return;
  }

  const Component = getHeaderComponent(content);

  if (!Component) {
    return '';
  } else {
    return (
      <Component
        content={content}
        logo={logo}
        showSwordLogo={showSwordLogo}
        redirectToApp={redirectToApp}
        noSpacerBottom={noSpacerBottom}
        userName={userName}
      />
    );
  }
}
