import ImageMarquee from '@/app/components/shared/Marquee';
import DynamicHero from '@/app/components/platform/DynamicHero';

const HeaderSlider = ({ content, logo, showSwordLogo = true, noSpacerBottom, userName }) => {
  const images = content.slider;

  return (
    <DynamicHero
      content={content?.content}
      logo={logo}
      showSwordLogo={showSwordLogo}
      noSpacerBottom={noSpacerBottom}
      userName={userName}
    >
      <ImageMarquee images={images} />
    </DynamicHero>
  );
};

export default HeaderSlider;
