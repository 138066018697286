import { useEffect, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';
import { usePathname } from 'next/navigation';
import Lottie from 'react-lottie';

import getImgSrcWorkaround from '@/utils/images';
import useReducedMotion from '@/utils/useReducedMotion';

import { VideoContainer, LottieWrapper } from './styles';
import DynamicHero from '../DynamicHero';

const COOKIES_KEY = 'sword_cookies_header_video';
const COOKIES_DOMAIN = process.env.NEXT_PUBLIC_COOKIES_DOMAIN || '.swordhealth.com';

function getShownVideo(content, number) {
  const { video, video2, video3 } = content;

  if (number == 0 || number == 1) {
    return video;
  } else if (number == 2 && (video2?.url ?? video2?.data?.attributes?.url)) {
    return video2;
  } else if (number >= 3 && (video3?.url ?? video3?.data?.attributes?.url)) {
    return video3;
  }
  return video;
}

const HeaderVideo = ({ content, logo, showSwordLogo = true, noSpacerBottom, userName }) => {
  const prefersReducedMotion = useReducedMotion();
  const COOKIES_PATH = usePathname();
  const [shownVideo, setShownVideo] = useState(null);
  const [countVideo] = useState(
    getCookie(COOKIES_KEY, { path: COOKIES_PATH, domain: COOKIES_DOMAIN }) ?? 1,
  );

  useEffect(() => {
    let addVideo = countVideo;
    addVideo++;
    setCookie(COOKIES_KEY, addVideo, { path: COOKIES_PATH, domain: COOKIES_DOMAIN });
    setShownVideo(getShownVideo(content.video, countVideo));
  }, []);

  return (
    <DynamicHero
      content={content?.content}
      logo={logo}
      showSwordLogo={showSwordLogo}
      noSpacerBottom={noSpacerBottom}
      userName={userName}
    >
      {shownVideo?.ext === '.json' || shownVideo?.data?.attributes?.ext === '.json' ? (
        <LottieWrapper>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              path: getImgSrcWorkaround(shownVideo?.url ?? shownVideo?.data?.attributes?.url),
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet',
              },
            }}
            style={{
              transform: 'none',
            }}
          />
        </LottieWrapper>
      ) : shownVideo?.ext === '.mp4' || shownVideo?.data?.attributes?.ext === '.mp4' ? (
        <VideoContainer autoPlay={!prefersReducedMotion} playsInline loop muted>
          <source src={getImgSrcWorkaround(shownVideo?.url ?? shownVideo?.data?.attributes?.url)} />
        </VideoContainer>
      ) : (
        <></>
      )}
    </DynamicHero>
  );
};

export default HeaderVideo;
