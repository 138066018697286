import styled from 'styled-components';
import { MarqueeSection } from './MarqueeSection';

export const Marquee = ({ images, reversed, imageHovered, setImageHovered, matches }) => {
  const commonProps = {
    images,
    reversed,
    imageHovered,
    setImageHovered,
    matches,
  };

  return (
    <Wrapper $reversed={reversed}>
      <MarqueeSection {...commonProps} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  top: 0;
  margin: 0;
  padding: 0;
  width: calc((100% - 48px) / 3);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-self: ${(props) => (props.$reversed ? 'flex-end' : 'flex-start')};

  &:last-child {
    top: 200px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    flex-direction: row;
    width: auto;
    gap: 16px;

    &:last-child {
      top: auto;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    gap: 8px;
  }
`;
