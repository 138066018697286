import styled from 'styled-components';

export const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-height: 432px;
    justify-self: center;
    aspect-ratio: 9/16;
  }
`;

export const LottieWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-height: 432px;
    justify-self: center;
    aspect-ratio: 9/16;
  }

  svg {
    transform: none !important;
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      max-height: 400px;
    }
  }
`;
