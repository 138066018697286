import React, { useState } from 'react';

import { Marquee } from './Marquee';
import Media from 'react-media';

import theme from '@/utils/styles-variables';
import styled from 'styled-components';

const ImageMarquee = ({ images }) => {
  const [imageHovered, setImageHovered] = useState(-1);

  const splitImages = (images, count) => {
    const minChunkSize = Math.floor(images.length / count); // minimum number of images per chunk
    const remainder = images.length % count; // remaining images after distributing evenly
    let startIndex = 0;
    let endIndex = 0;
    const result = [];

    for (let i = 0; i < count; i++) {
      const chunkSize = i < remainder ? minChunkSize + 1 : minChunkSize;
      endIndex = startIndex + chunkSize;
      result.push(images.slice(startIndex, endIndex));
      startIndex = endIndex;
    }

    return result;
  };

  const renderMarquee = (images, reversed = false, matches) => (
    <Marquee
      images={images}
      reversed={reversed}
      imageHovered={imageHovered}
      setImageHovered={setImageHovered}
      matches={matches}
    />
  );

  return (
    <Wrapper>
      <Media query={{ minWidth: theme.breakpoints.min_lg }} defaultMatches={false}>
        {(matches) => (
          <>
            {splitImages(images, matches ? 3 : 2).map((imageGroup, index) => (
              <React.Fragment key={index}>
                {renderMarquee(imageGroup, index % 2 === 0, matches)}
              </React.Fragment>
            ))}
          </>
        )}
      </Media>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  max-width: 632px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  margin-right: 36px;

  &:before {
    top: 0;
    left: 0;
    z-index: 1;
    content: '';
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 200px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    position: relative;
    margin: 64px -36px 0 -36px;
    left: 0;
    max-width: none;
    flex-direction: column;

    gap: 16px;

    &:before {
      display: none;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    margin: 40px -24px 0 -24px;
    gap: 8px;
  }
`;

export default ImageMarquee;
