import Image from 'next/image';
import styled, { css } from 'styled-components';
import getImgSrcWorkaround from '@/utils/images';

export const MarqueeSection = ({ images, reversed, imageHovered, setImageHovered, matches }) => (
  <Wrapper
    $reversed={reversed}
    $duration={(images.length * 424) / 12.5}
    $hovered={imageHovered > 0}
  >
    {images?.map((content) => (
      <ImageWrapper
        key={content.id}
        onMouseEnter={() => setImageHovered(content.id)}
        onMouseLeave={() => setImageHovered(-1)}
      >
        {matches &&
          imageHovered === content.id &&
          (content?.video?.url ?? content?.video?.data?.attributes?.url) && (
            <Video autoPlay playsInline loop muted>
              <source
                src={getImgSrcWorkaround(
                  content.video.url ?? content?.video?.data?.attributes?.url,
                )}
              />
            </Video>
          )}
        <Image
          src={getImgSrcWorkaround(content?.image?.url ?? content?.image?.data?.attributes?.url)}
          alt={
            content?.image?.alternativeText ??
            content?.image?.data?.attributes?.alternativeText ??
            ''
          }
          fill
          loading="eager"
          sizes="(max-width: 768px) 180px, (max-width: 991px) 280px, 200px"
        />
      </ImageWrapper>
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  animation: ${({ $reversed, $duration }) =>
      $reversed ? `scroll-down ${$duration}s` : `scroll-up ${$duration}s`}
    linear infinite;

  ${({ $hovered }) =>
    $hovered &&
    css`
      animation-play-state: paused;
    `};

  @media (prefers-reduced-motion) {
    animation-play-state: paused;
  }

  @keyframes scroll-up {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }

  @keyframes scroll-down {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }

  @keyframes scroll-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes scroll-right {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 16px;
    animation: ${({ $reversed, $duration }) =>
        $reversed ? `scroll-right ${$duration}s` : `scroll-left ${$duration}s`}
      linear infinite;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    gap: 8px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 24px;
  img {
    object-position: center;
    object-fit: cover;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    min-width: 280px;
    width: 280px;
    height: 160px;
    margin-bottom: 0;

    img {
      object-position: 50% 20%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    min-width: 180px;
    width: 180px;
    height: 100px;
  }
`;

const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;

  animation-duration: 0.3s;
  animation-name: animate-fade;
  animation-delay: 0.1s;
  animation-fill-mode: backwards;

  @keyframes animate-fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
